import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ServiceCard from "./serviceCard"

const Service = () => {
  const data = useStaticQuery(graphql`
    query {
      trust: file(relativePath: { eq: "trust.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      loan: file(relativePath: { eq: "loan.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      newCompany: file(relativePath: { eq: "newCompany.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="service">
      <div className="service__container">
        <h1 className="service__title">取扱業務</h1>
        <div className="service__list">
          <section className="service__card">
            <ServiceCard
              fluidImage={data.trust.childImageSharp.fluid}
              linkAddr={"/consul/"}
              cardTitle={"相続・遺言・家族信託"}
              cardText={
                "相続でお悩みの方、将来の相続対策をお考えの方、有効な資産管理を信託で解決したい方など。"
              }
            />
          </section>
          <section className="service__card">
            <ServiceCard
              fluidImage={data.loan.childImageSharp.fluid}
              linkAddr={"/regist/"}
              cardTitle={"抵当権抹消・住所変更・贈与"}
              cardText={
                "住宅ローンを完済した方、お引っ越しにより住所変更された方、贈与等による不動産の名義を変更したい方など。"
              }
            />
          </section>
          <section className="service__card">
            <ServiceCard
              fluidImage={data.newCompany.childImageSharp.fluid}
              linkAddr={"/corp/"}
              cardTitle={"会社設立・会社役員変更"}
              cardText={
                "会社を新しく立ち上げたい方、会社の役員の変更があった、定款の見直しをしたい方など。"
              }
            />
          </section>
        </div>
      </div>
    </section>
  )
}

export default Service
