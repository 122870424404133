import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Main from "../components/main"

const IndexPage = () => (
  <Layout>
    <SEO title="大阪の司法書士 | 相続　遺言　家族信託　登記全般" />
    <Main />
  </Layout>
)

export default IndexPage
