import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ServiceCard = ({ fluidImage, linkAddr, cardTitle, cardText }) => {
  return (
    <>
      <Link to={linkAddr}>
        <Img fluid={fluidImage} className="service__card__img" />
        <div className="service__card__content">
          <h2 className="service__card__title">{cardTitle}</h2>
          <p className="service__card__text">{cardText}</p>
        </div>
      </Link>
    </>
  )
}

export default ServiceCard
