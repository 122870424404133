import React from "react"
import BlogNews from "./blogNews"
import Company from "./company"
import Helo from "./hero"
import Service from "./service"

const Main = () => {
  return (
    <>
      <div className="main__container">
        <Helo />
        <Company />
        <Service />
        <BlogNews />
      </div>
    </>
  )
}

export default Main
