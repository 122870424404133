import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faNewspaper } from "@fortawesome/free-regular-svg-icons"

const BlogNews = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulBlogPost(
        sort: { fields: createdDate, order: DESC }
        skip: 0
        limit: 4
      ) {
        edges {
          node {
            title
            slug
            id
            createdDate(formatString: "YYYY年MM月DD日 HH:mm")
          }
        }
      }
    }
  `)

  return (
    <section className="content bloglist">
      <div className="bloglist__container">
        <h1 className="bloglist__title">新着記事</h1>
        <div className="bloglist__posts">
          {data.allContentfulBlogPost.edges.map(({ node }) => (
            <Link to={`/blog/news/${node.slug}/`} key={node.id}>
              <article className="bloglist__post">
                <FontAwesomeIcon icon={faNewspaper} />
                <h2 className="bloglist__post__title">{node.title}</h2>
                <span className="bloglist__post__date">
                  投稿日：{node.createdDate}
                </span>
              </article>
            </Link>
          ))}
        </div>
        <Link to={'/blog/'}>
          <button className="link__btn">お知らせ・ブログ一覧</button>
        </Link>
      </div>
    </section>
  )
}

export default BlogNews
