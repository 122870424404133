import React from "react"
import BackContact from "./backContact"

const Company = () => {
  return (
    <section className="company">
      <div className="company__container">
        <h1 className="company__title">司法書士法人みつわ合同事務所の紹介</h1>
        <div className="company__desc">
          <p className="company__desc_text">
            司法書士法人みつわ合同事務所は、大阪で創業５０年を迎え、皆様のお力になれるように、努めてまいりました。
            今後も、時代の変化、お客様のニーズにお応えできるよう励んで参ります。
            <br />
            なお、お電話・メールの他、ZOOM等のオンラインでの相談も実施しておりますので、お困りのこと、分からないことがありましたら、
            お気軽にご相談ください。
          </p>
          <BackContact link={"/about/"} linkName={"事務所概要"} />
        </div>
      </div>
    </section>
  )
}

export default Company
