import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const Helo = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="hero">
      <div className="hero__container">
        <Img fluid={data.hero.childImageSharp.fluid} className="hero__img" />
        <div className="hero__title__container">
          <h1 className="hero__title">司法書士法人みつわ合同事務所</h1>
          <p className="hero__subTitle">
            大阪メトロ御堂筋線本町駅
            <br />
            徒歩すぐの司法書士事務所
          </p>
        </div>
      </div>
    </section>
  )
}

export default Helo
